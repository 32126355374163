function addUserProperties(props) {
  console.log(`Added User Propeties > ${JSON.stringify(props, null, 2)}`);
  window.dataLayer.push({
    event: 'AddUserProperties',
    userProperties: props,
  });
}

export default class Analytics {
  static trackViewElement(element) {
    console.log(`Viewed Element > ${element}`);
    window.dataLayer.push({
      event: 'ViewElement',
      viewedElement: element,
    });
  }

  static trackClickElement(element) {
    console.log(`Clicked > ${element}`);
    window.dataLayer.push({
      event: 'ClickElement',
      clickedElement: element,
    });

    window.dataLayer.push({
      event: `Clicked ${element}`,
    });
  }

  static trackViewPage(to) {
    console.log(`Viewed Page > ${to.name}`);
    window.dataLayer.push({
      event: 'ViewPage',
      routerPath: to.path,
      routerTitle: to.meta.title,
    });
  }

  static trackCartSync(state) {
    console.log('Cart Sync');
    window.dataLayer.push({
      event: 'CartReset',
      email: undefined,
      cart: undefined,
      coupon: undefined,
      shipping: undefined,
    });
    window.dataLayer.push({
      event: 'CartSync',
      email: state.email,
      cart: state.cart,
      coupon: state.coupon,
      shipping: state.shipping,
    });
  }

  static trackProductImpressions(originList, productList) {
    console.log(`Product Impression > ${JSON.stringify(productList.map(p => p.product.id))}`);
    window.dataLayer.push({
      event: 'eec.ProductImpression',
      ecommerce: {
        currencyCode: 'USD', // Local currency is optional.
        impressions: productList.map((product, pos) => ({
          name: product.product.title, // Name or ID is required.
          id: product.product.id,
          price: parseFloat((product.product.price / 100.0)).toFixed(2),
          brand: 'FightCamp',
          category: product.product.category,
          list: originList,
          position: pos + 1,
        })),
      },
    });
    window.dataLayer.push({
      event: 'ProductImpressions',
      impressions: productList.map(product => product.product),
    });
    window.dataLayer.push({
      event: 'view_item_list',
      aw_value: parseFloat((productList.reduce((acc, product) => acc + product.product.price, 0)) / 100).toFixed(2),
      aw_items: productList.map(product => ({
        id: product.product.gmc_id,
        google_business_vertical: 'retail',
      })),
    });
  }

  static trackProductClick(originList, productList, pos = 1) {
    console.log(`Product Click > ${JSON.stringify(productList.map(p => p.id))}`);
    window.dataLayer.push({
      event: 'eec.ProductClick',
      ecommerce: {
        currencyCode: 'USD', // Local currency is optional.
        click: {
          actionField: {
            list: originList,
          },
          products: productList.map(product => ({
            name: product.title, // Name or ID is required.
            id: product.id,
            price: parseFloat((product.price / 100.0)).toFixed(2),
            brand: 'FightCamp',
            category: product.category,
            position: pos,
          })),
        },
      },
    });
  }

  // TODO move view_item event to segment and remove trackProductDetailsImpression
  static trackProductDetailsImpression(originList, productList) {
    console.log(`Product Details Impression > ${JSON.stringify(productList.map(p => p.id))}`);
    window.dataLayer.push({
      event: 'eec.ProductDetailsImpression',
      ecommerce: {
        currencyCode: 'USD',
        detail: {
          actionField: {
            list: originList,
          },
          products: productList.map((product) => ({
            name: product.product.title,
            id: product.product.id,
            price: parseFloat((product.product.price / 100.0)).toFixed(2),
            brand: 'FightCamp',
            category: product.product.category,
          })),
        },
      },
    });
    window.dataLayer.push({
      event: 'ProductDetailsImpression',
      impressionDetails: productList.map(product => product.product),
    });
    window.dataLayer.push({
      event: 'view_item',
      aw_value: parseFloat((productList.reduce((acc, product) => acc + product.product.price, 0)) / 100).toFixed(2),
      aw_items: productList.map(product => ({
        id: product.product.gmc_id,
        google_business_vertical: 'retail',
      })),
    });
  }

  static trackAddToCart(variantList) {
    console.log(`Add To Cart > ${JSON.stringify(variantList.map(p => p.product.id))}`);
    window.dataLayer.push({
      event: 'eec.AddToCart',
      ecommerce: {
        currencyCode: 'USD',
        add: {
          products: variantList.map((variant) => ({
            name: variant.product.title,
            id: variant.product.id,
            price: parseFloat((variant.price / 100.0)).toFixed(2),
            brand: 'FightCamp',
            category: variant.product.category,
            quantity: 1,
            variant: variant.id,
          })),
        },
      },
    });

    variantList.forEach((variant) => {
      window.dataLayer.push({
        event: 'AddToCart',
        addToCartItem: {
          name: variant.product.title,
          fbid: variant.product.fbid,
          id: variant.product.id,
          price: parseFloat((variant.price / 100.0)).toFixed(2),
          brand: 'FightCamp',
          category: variant.product.category,
          quantity: 1,
          variant: variant.id,
        },
      });
    });
    window.dataLayer.push({
      event: 'add_to_cart',
      aw_value: parseFloat((variantList.reduce((acc, product) => acc + product.product.price, 0)) / 100).toFixed(2),
      aw_items: variantList.map(product => ({
        id: product.product.gmc_id,
        google_business_vertical: 'retail',
      })),
    });
  }

  static trackRemoveFromCart(variantList) {
    console.log(`Remove From Cart > ${JSON.stringify(variantList.map(p => p.product.id))}`);
    window.dataLayer.push({
      event: 'eec.RemoveFromCart',
      ecommerce: {
        currencyCode: 'USD',
        remove: {
          products: variantList.map((variant) => ({
            name: variant.product.title,
            id: variant.product.id,
            price: parseFloat((variant.price / 100.0)).toFixed(2),
            brand: 'FightCamp',
            category: variant.product.category,
            quantity: 1,
            variant: variant.id,
          })),
        },
      },
    });

    variantList.forEach((variant) => {
      window.dataLayer.push({
        event: 'RemoveFromCart',
        removeFromCartItem: {
          name: variant.product.title,
          id: variant.product.id,
          fbid: variant.product.fbid,
          price: parseFloat((variant.price / 100.0)).toFixed(2),
          brand: 'FightCamp',
          category: variant.product.category,
          quantity: 1,
          variant: variant.id,
        },
      });
    });
  }

  static trackCheckoutLogin(variantList) {
    console.log(`Checkout Login > ${JSON.stringify(variantList.map(p => p.product.id))}`);
    window.dataLayer.push({
      event: 'eec.CheckoutLogin',
      ecommerce: {
        currencyCode: 'USD',
        checkout: {
          actionField: {
            step: 1,
          },
          products: variantList.map((variant) => ({
            name: variant.product.title,
            id: variant.product.id,
            price: parseFloat((variant.price / 100.0)).toFixed(2),
            brand: 'FightCamp',
            category: variant.product.category,
            quantity: variant.qty,
            variant: variant.id,
          })),
        },
      },
    });
  }

  static trackCheckoutShipping(variantList) {
    console.log(`Checkout Shipping > ${JSON.stringify(variantList.map(p => p.product.id))}`);
    window.dataLayer.push({
      event: 'eec.CheckoutShipping',
      ecommerce: {
        currencyCode: 'USD',
        actionField: {
          step: 2,
        },
        checkout: {
          products: variantList.map((variant) => ({
            name: variant.product.title,
            id: variant.product.id,
            price: parseFloat((variant.price / 100.0)).toFixed(2),
            brand: 'FightCamp',
            category: variant.product.category,
            quantity: variant.qty,
            variant: variant.id,
          })),
        },
      },
    });
  }

  static trackCheckoutPayment(variantList) {
    console.log(`Checkout Payment > ${JSON.stringify(variantList.map(p => p.product.id))}`);
    window.dataLayer.push({
      event: 'eec.CheckoutPayment',
      ecommerce: {
        currencyCode: 'USD',
        checkout: {
          actionField: {
            step: 3,
          },
          products: variantList.map((variant) => ({
            name: variant.product.title,
            id: variant.product.id,
            price: parseFloat((variant.price / 100.0)).toFixed(2),
            brand: 'FightCamp',
            category: variant.product.category,
            quantity: variant.qty,
            variant: variant.id,
          })),
        },
      },
    });
  }

  static trackTransactionAttempt(variantList, method) {
    console.log(`Transaction Attempt > ${method} - ${JSON.stringify(variantList.map(p => p.product.id))}`);
    window.dataLayer.push({
      event: 'eec.TransactionAttempt',
      ecommerce: {
        currencyCode: 'USD',
        checkout: {
          actionField: {
            step: 4,
            option: method,
          },
          products: variantList.map((variant) => ({
            name: variant.product.title,
            id: variant.product.id,
            price: parseFloat((variant.price / 100.0)).toFixed(2),
            brand: 'FightCamp',
            category: variant.product.category,
            quantity: variant.qty,
            variant: variant.id,
          })),
        },
      },
    });

    window.dataLayer.push({
      event: 'TransactionAttempt',
      transactionAttempt: {
        method,

      },
    });
  }

  static trackPurchase(order, bundle, method, orderNumber, newCustomer) {
    console.log(`Checkout Purchase > ${orderNumber} ${JSON.stringify(bundle.map(p => p.product.title))}`);
    window.dataLayer.push({
      event: 'eec.Purchase',
      ecommerce: {
        currencyCode: 'USD',
        purchase: {
          actionField: {
            id: orderNumber, // Transaction ID. Required for purchases and refunds.
            affiliation: 'Online Store',
            revenue: parseFloat(order.costBreakdown.totalCost / 100.0).toFixed(2), // Total transaction value (incl. tax and shipping)
            tax: parseFloat(order.costBreakdown.productTax / 100.0).toFixed(2),
            shipping: parseFloat(order.costBreakdown.productShipping / 100.0).toFixed(2),
            coupon: order.costBreakdown.couponName,
          },
          products: bundle.map(variant => ({
            name: variant.product.title,
            id: variant.product.id,
            price: parseFloat((variant.product.price / 100.0)).toFixed(2),
            brand: 'FightCamp',
            category: variant.product.category,
            quantity: variant.qty,
            variant: variant.id,
            subid: variant.subid,
          })),
        },
      },
    });

    window.dataLayer.push({
      event: 'Purchase',
      order: {
        cart: bundle.map(variant => ({
          ...variant,
          price: parseFloat(variant.price / 100.0).toFixed(2),
        })),
        id: orderNumber,
        affiliation: 'Online Store',
        revenue: parseFloat(order.costBreakdown.totalCost / 100.0).toFixed(2), // Total transaction value (incl. tax and shipping)
        tax: parseFloat(order.costBreakdown.productTax / 100.0).toFixed(2),
        shipping: parseFloat(order.costBreakdown.productShipping / 100.0).toFixed(2),
        coupon: order.costBreakdown.couponName,
        method,
        new_customer: newCustomer,
      },
    });

    window.dataLayer.push({
      event: 'purchase',
      aw_value: parseFloat((bundle.reduce((acc, product) => acc + product.product.price, 0)) / 100).toFixed(2),
      aw_items: bundle.map(product => ({
        id: product.product.gmc_id,
        google_business_vertical: 'retail',
      })),
    });
  }

  static trackHeardAboutUs(email, orderId, heardText, salesRep = null, followup = null) {
    console.log(`Checkout Purchase > ${email}, ${orderId},${heardText},${salesRep},${followup},`);
    window.dataLayer.push({
      event: 'HeardAboutUsSurvey',
      heardAboutUs: {
        email,
        orderId,
        heardText,
        salesRep,
        followup,
      },
    });
  }

  static identifyByID(id) {
    console.log(`Identify By ID > ${id}`);
    window.dataLayer.push({
      event: 'IdentifyByID',
      userID: id,
    });
  }

  static identifyByEmail(email) {
    console.log(`Identify By Email > ${email}`);
    window.dataLayer.push({
      event: 'IdentifyByEmail',
      userEmail: email,
    });
  }

  static SubscribeToKickboxingEmail() {
    console.log('Subscribe To Kickboxing Email');
    window.dataLayer.push({
      event: 'SubscribeToKickboxingEmail',
    });
  }

  static SubscribeToJournalEmail() {
    console.log('Subscribe To Journal Download Email');
    window.dataLayer.push({
      event: 'SubscribeToJournalEmail',
    });
  }

  static SubscribeFromAboutUsPage() {
    console.log('Subscribe To Stay In The Know');
    window.dataLayer.push({
      event: 'AboutUsSubscribe',
    });
  }

  static SubscribeFromAndroidPage() {
    console.log('Subscribe To - Android Coming Soon');
    window.dataLayer.push({
      event: 'AndroidSubscribe',
    });
  }

  static brochurePopUp(state) {
    console.log(`Brochure Pop Up > ${state}`);
    window.dataLayer.push({
      event: `BrochurePopUp${state}`,
    });
  }

  static brochureFooter(state) {
    console.log(`Brochure Footer > ${state}`);
    window.dataLayer.push({
      event: `BrochureFooter${state}`,
    });
  }

  static brochurePopUpPhoneNumber(state, number) {
    console.log(`Brochure Pop Up Phone Number > ${state}`);
    window.dataLayer.push({
      event: `BrochurePopUpPhoneNumber${state}`,
      userPhone: number,
    });
  }

  static setSmsConsent(number) {
    console.log('SMS Consent');
    window.dataLayer.push({
      event: 'SmsConsent',
      userPhone: number,
    });
  }

  static smsAppInstallEvent({ number, email, url }) {
    console.log('SMS Text me the app install');
    window.dataLayer.push({
      event: 'SmsAppInstall',
      userPhone: number,
      userEmail: email,
      appInstallUrl: url,
    });
  }

  static trackPromoCodeAttempted(name, valid, error) {
    console.log(`Attempted Promo Code > ${name}`);
    window.dataLayer.push({
      event: 'AttemptedPromoCode',
      promoCode: {
        name,
        valid,
        error,
      },

    });
  }

  static setName(first, last) {
    console.log(`Set Name properties > ${first} ${last}`);
    addUserProperties({
      firstName: first,
      lastName: last,
    });
  }

  static setPhoneNumber(phone) {
    console.log(`Set Phone property > ${phone}`);
    addUserProperties({
      phone,
    });
  }

  static setAddress(address, state, postalCode, city, address2) {
    console.log(`Set Address property > ${JSON.stringify(address, null, 2)}`);
    console.log(`Set state property > ${JSON.stringify(state, null, 2)}`);
    console.log(`Set postalCode property > ${JSON.stringify(postalCode, null, 2)}`);
    console.log(`Set city property > ${JSON.stringify(city, null, 2)}`);
    console.log(`Set address2 property > ${JSON.stringify(address2, null, 2)}`);
    addUserProperties({
      address,
      state,
      postalCode,
      city,
      address2,
    });
  }

  static accountCreated(provider) {
    console.log(`Sign Up Web > ${provider}`);
    window.dataLayer.push({
      event: 'SignUp',
      auth: {
        type: 'web',
        provider,
      },
    });

    addUserProperties({
      createdAt: new Date().toISOString(),
    });
  }

  static loggedIn(provider) {
    console.log(`Sign In Web > ${provider}`);
    window.dataLayer.push({
      event: 'SignIn',
      auth: {
        type: 'web',
        provider,
      },
    });
  }

  static loggedOut() {
    console.log('Sign Out Web');
    window.dataLayer.push({
      event: 'SignOut',
      auth: {
        type: 'web',
      },
    });
  }

  static sendSMSPrePurchase(phone) {
    window.dataLayer.push({
      event: 'SMSPrePurchase',
      userPhone: phone,
    });

    addUserProperties({
      phone,
    });
  }

  static abandonedCart(variants) {
    const products = variants.map(variant => variant.product).filter(el => el != null);
    console.log(`Abandoned Cart > ${products.map(product => product.id)}`);
    window.dataLayer.push({
      event: 'AbandonedCart',
    });
  }

  static triggerDrift() {
    window.dataLayer.push({
      event: 'triggerDrift',
    });
  }
}
