<template>
  <svg
    class="fc-cart"
    :class="{'fc-cart--hover': changeColorOnHover}"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    aria-labelledby="cart icon"
    role="presentation">
    <title
      id="cart icon"
      lang="en">cart icon</title>
    <path
      d="M6.785,962.362a1.769,1.769,0,1,0,0,3.538h5.639L18.5,994.591c.241,1.08.948,1.979,1.843,1.972H49.831a1.769,1.769,0,1,0,0-3.538H21.785l-.756-3.538H52.19A1.824,1.824,0,0,0,53.9,988.1l4.128-17.69a1.851,1.851,0,0,0-1.714-2.156H16.551l-.958-4.5a1.825,1.825,0,0,0-1.732-1.4Zm10.5,9.435h36.8l-3.3,14.152h-30.5Zm8.956,25.946a5.9,5.9,0,1,0,5.9,5.9A5.924,5.924,0,0,0,26.244,997.742Zm17.69,0a5.9,5.9,0,1,0,5.9,5.9A5.924,5.924,0,0,0,43.934,997.742Zm-17.69,3.538a2.359,2.359,0,1,1-2.359,2.359A2.332,2.332,0,0,1,26.244,1001.28Zm17.69,0a2.359,2.359,0,1,1-2.359,2.359A2.332,2.332,0,0,1,43.934,1001.28Z"
      transform="translate(-5.016 -962.362)" />
    <circle
      v-show="isItemInCart && displayQty"
      cx="46"
      cy="6"
      r="14" />
    <text
      v-show="isItemInCart && displayQty"
      x="46"
      y="13"> {{ cartQty }} </text>
  </svg>
</template>

<script lang="ts">
  import { defineComponent, computed } from '@vue/composition-api';
  import { FCCartProps } from './types';

  export default defineComponent({
    name: 'FCCart',
    props: {
      displayQty: {
        type: Boolean,
        required: false,
        default: false,
      },
      width: {
        type: String,
        required: false,
        default: '30px',
      },
      height: {
        type: String,
        required: false,
        default: '30px',
      },
      viewBox: {
        type: String,
        required: false,
        default: '0 0 53.052 47.174',
      },
      cartQty: {
        type: Number,
        required: false,
        default: 0,
      },
      changeColorOnHover: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    setup(props: FCCartProps) {
      const isItemInCart = computed(() => (props?.cartQty ?? 0) > 0);

      return {
        isItemInCart,
      };
    },
  });
</script>

<style scoped lang="scss">
@import "../../styles/exports";

.fc-cart {
  path {
    fill: $color-black;
  }

  &--hover {
    &:hover {
      path {
        fill: $color-red;
      }
    }
  }

  circle {
    fill: $color-red;
  }

  text {
    font: get-font-size-level(7) $font-stack-heading;
    text-anchor: middle;
    fill: $color-white;
  }
}
</style>
