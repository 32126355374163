import axios from 'axios';

const fightCampBackendLink = process.env.VUE_APP_API_URL;

type AmountInCents = number;
type CreateOrderParams = {
 requestBody: { shippingAddress: {
    address1: string,
    address2?: string | null,
    city: string,
    state: string,
    country: string,
    firstName: string,
    lastName: string,
    name: string,
    phone: string,
    postalCode: string,
  };
  itemList: {variantId: String; quantity: Number}[]};
  email: String;
  costBreakdown: {
    totalCost: AmountInCents, productTax: AmountInCents, productCost: AmountInCents, productShipping: AmountInCents
  };
  customerId: String;
}

// TODO write tests now and mock axios
export default {
  getOrder(paymentIntentId: string) {
    return axios.get(`${fightCampBackendLink}/orders?paymentIntentId=${paymentIntentId}`, {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  createOrder({ requestBody }: CreateOrderParams) {
    console.log('requestBody', requestBody);
    return axios.post(`${fightCampBackendLink}/orders`, JSON.stringify(requestBody), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  updateOrder({ paymentIntentId, requestBody }) {
    return axios.put(`${fightCampBackendLink}/orders/${paymentIntentId}`, JSON.stringify(requestBody), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  confirmOrder({ requestBody }) {
    return axios.post(`${fightCampBackendLink}/orders/confirm`, JSON.stringify(requestBody), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  getCustomer({ email = '', id = '' }: { email?: string, id?: string } = {}) {
    /*
      The email parameter is passed to encodeURIComponent to ensure that any special characters
      (including '+') are properly encoded. This is necessary because in URLs, '+' is interpreted as a space.
      So, if you want to include an actual '+' character in a URL, you need to URL encode it as '%2B'.
      encodeURIComponent takes care of this encoding for us.
    */

    if (!email && !id) {
      throw new Error('Either email or id must be provided');
    } else {
      const queryParam = email ? `email=${encodeURIComponent(email)}` : `id=${encodeURIComponent(id)}`;
      return axios.get(`${fightCampBackendLink}/customers?${queryParam}`, {
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
      });
    }
  },
  createCustomer(requestBody) {
    return axios.post(`${fightCampBackendLink}/customers`, JSON.stringify(requestBody), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  updateCustomer({ id, requestBody }: { id: string; requestBody: any }) {
    return axios.put(`${fightCampBackendLink}/customers/${id}`, JSON.stringify(requestBody), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  createSetupIntent({ customerId, paymentMethodId }: {customerId: string; paymentMethodId: string}) {
    return axios.post(`${fightCampBackendLink}/setup-intents`, JSON.stringify({ customerId, paymentMethodId }), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
};
