import { StoryblokComponent } from 'storyblok-js-client';
import { ShortTextProps } from '@fc/angie-ui/src/components/ShortText/types';
import { VideoPlayback } from '@fc/angie-ui/src/components/Video/types';
import { LinkButtonProps } from '@fc/angie-ui/src/components/LinkButton/types';
import { Store } from 'vuex';
import { MediaBackgroundProps, Position, BGColor } from '~/components/storyblok/MediaBackground/types';
import { IUniqueProps } from '~/components/storyblok/Unique/types';
import { getProduct } from '~/utils/accessoryBundleProducts';

export interface IStoryblokMedia {
  alt: string,
  copyright: string,
  fieldtype: string,
  filename: string,
  focus: string,
  id: string,
  is_external_url: string,
  name: string,
  title: string,
}

export interface IStoryblokLinkButton extends StoryblokComponent<string> {
  ctaText: string;
  ctaLink: {
    cached_url: string;
    linktype: string;
  },
  codeId: string;
  buttonTheme: string;
  buttonTextSize: string;
  buttonAlignment: string;
  fullWidth: boolean;
  mobileAlignmentCenter: boolean;
}

export function transformLinkButtonContent(contentObj: IStoryblokLinkButton): LinkButtonProps {
  return {
    ctaText: contentObj?.ctaText,
    ctaLink: contentObj?.ctaLink?.cached_url,
    buttonTheme: contentObj?.buttonTheme,
    buttonSize: contentObj?.buttonTextSize,
    buttonAlignment: contentObj?.buttonAlignment,
    fullWidth: contentObj?.fullWidth,
    elementName: contentObj?.ctaLink?.linktype === 'story' ? 'NuxtLink' : 'a',
    'data-testid': contentObj?.codeId ?? 'atf-button',
    mobileAlignmentCenter: contentObj?.mobileAlignmentCenter ?? false,
  };
}

export interface IStoryblokShortText extends StoryblokComponent<string> {
  text: string;
  isBold: boolean;
  textColor: string;
  textTheme: string;
  elementName: string;
  isUpperCase: boolean;
  textAlignment: string[];
  paddingTop: string;
  paddingBottom: string;
  paddingLeft: string;
  paddingRight: string;
  maxWidth: string;
}

const handleStringArray = (content: string | string[]) => (Array.isArray(content) ? content.join(' ') : content);

export function transformShortTextContent(contentObj: IStoryblokShortText): ShortTextProps {
  return {
    text: contentObj?.text,
    textTheme: contentObj?.textTheme,
    elementName: contentObj?.elementName,
    textColor: contentObj?.textColor,
    isBold: contentObj?.isBold,
    textAlignment: handleStringArray(contentObj?.textAlignment),
    isUpperCase: contentObj?.isUpperCase,
    maxWidth: contentObj?.maxWidth,
    paddingProperties: {
      paddingTop: contentObj?.paddingTop,
      paddingBottom: contentObj?.paddingBottom,
      paddingLeft: contentObj?.paddingLeft,
      paddingRight: contentObj?.paddingRight,
    },
  };
}

export interface IStoryblokPictureResponsive extends StoryblokComponent<string> {
  imageType: string;
  Image: IStoryblokMedia;
  component: string;
  rounded: boolean;
}

export function transformPictureResponsiveContent(contentObj: IStoryblokPictureResponsive): { src: string, alt: string } {
  return {
    src: contentObj?.Image.filename,
    alt: contentObj?.Image.alt,
  };
}

export interface IStoryblokVideo extends StoryblokComponent<string> {
  component: string;
  isOverlay: boolean;
  playsAudio: boolean;
  rounded: boolean;
  hasControls: boolean;
  hasPlayIcon: boolean;
  mp4VideoUrl: string;
  webmVideoUrl?: string;
  firstFrameImage: IStoryblokMedia;
  mp4MobileVideoUrl?: string;
  webmMobileVideoUrl?: string;
  mobileVideoPlayback: VideoPlayback;
  tabletUpVideoPlayback: VideoPlayback;
}

export interface IStoryblokMediaBackground extends StoryblokComponent<string> {
  mediaContainer: [IStoryblokPictureResponsive, IStoryblokVideo],
  mediaPosition: Position;
  backgroundColor: BGColor;
  isFullWidth: boolean;
  brightness: number;
}

export function transformMediaBackgroundContent(contentObj: IStoryblokMediaBackground): MediaBackgroundProps {
  const videoComponent = contentObj?.mediaContainer.find(block => block.component === 'Video') as IStoryblokVideo;
  const pictureResponsiveComponent = contentObj?.mediaContainer.find(block => block.component === 'PictureResponsive') as IStoryblokPictureResponsive;

  return {
    imageSrc: pictureResponsiveComponent?.Image?.filename || '',
    imageAlt: pictureResponsiveComponent?.Image?.alt || '',
    firstFrameImageUrl: videoComponent?.firstFrameImage?.filename || '',
    webmVideo: videoComponent?.webmVideoUrl || '',
    mp4Video: videoComponent?.mp4VideoUrl || '',
    webmMobileVideo: videoComponent?.webmMobileVideoUrl || '',
    mp4MobileVideo: videoComponent?.mp4MobileVideoUrl || '',
    mobileVideoPlayback: videoComponent?.mobileVideoPlayback || VideoPlayback.auto,
    tabletUpVideoPlayback: videoComponent?.tabletUpVideoPlayback || VideoPlayback.auto,
    mediaPosition: Position[contentObj?.mediaPosition] || Position.center,
    backgroundColor: contentObj?.backgroundColor || BGColor.white,
    isFullWidth: contentObj?.isFullWidth || true,
    videoComponent,
    pictureResponsiveComponent,
    brightness: contentObj.brightness,
  };
}

export function transformUniqueContent(props: IUniqueProps) {
  return {
    ...props,
  };
}

export interface IStoryblokCarousel extends StoryblokComponent<string> {
  slides: any[];
}

export function transformCarouselContent(component: IStoryblokCarousel) {
  return {
    ...component,
  };
}

// TODO moves to fightcamp.ts https://github.com/fightcamp/fightcamp-web-monorepo/pull/949#discussion_r1538097065
export interface IStore {
  getters: {
    productsCollection: any[];
  };
}

interface IShopifyVariant {
  id: string;
  sku: string;
  title: string;
}

interface IShopifyProduct {
  id: string;
  sku: string | false;
  name: string;
  type: string;
  handle: string;
  description: string;
  variants: IShopifyVariant[];
}

/*
  this handles the logic when Storyblok returns a FCProducts component which contains
  a Shopify test plugin and a Shopify product plugin

  This method is used for package products like trackers and trackers + bag since hardcoded data varies for each product and
  many Storyblok components have hardcoded data for these packages as well
*/
export function transformFcProductContent(component: any, store: Store<IStore>) {
  const items = process.env.NUXT_ENV_IS_DEV === 'true' ? component?.shopifyTest?.items : component?.shopifyProd?.items;

  if (!store.getters.productsCollection) {
    return [];
  }

  return items
    .map((product: IShopifyProduct) => store.getters.productsCollection.find((item: IShopifyProduct) => item.id === product.id))
    .filter((item: IShopifyProduct) => item !== undefined);
}

interface ITransfromShopifyProdIdParams {
  testShopifyId: string;
  prodShopifyId: string;
  oldId: string;
  store: Store<IStore>;

}

/*
  this handles the logic when Storyblok returns a component that contains testShopifyId and
  prodShopifyId values

  This method is used for accessory/bundle products since they are more stand alone in our code and storyblok
*/
export function transformShopifyProdId({
  testShopifyId, prodShopifyId, oldId, store,
}: ITransfromShopifyProdIdParams) {
  const id = process.env.NUXT_ENV_IS_DEV === 'true' ? testShopifyId : prodShopifyId;
  const ecommData = store.getters.productsCollection.find((item: any) => item.id === id);
  const hardCodeData: any = getProduct(oldId) ?? {};

  // if item is found in Shopify return transformed item data if not return undefined
  if (ecommData) {
    return {
      ...hardCodeData,
      id: ecommData?.id,
      title: ecommData?.title,
      oldPrice: ecommData?.variants[0].compareAtPrice,
      imgUrl: ecommData?.image,
      price: ecommData?.variants[0].price,
      variants: ecommData?.variants,
      selects: hardCodeData?.selects ? {
        hint: hardCodeData?.selects?.hint,
        key: hardCodeData?.selects?.key,
        name: hardCodeData?.selects?.name,
        ...(ecommData?.variants && { options: [...ecommData?.variants ?? {}] }),
      } : null,
    };
  }

  return undefined;
}

/*
  Main takeaway from formatting Shopify data with our hard coded data is that our product data structure are not consistent
  Components are reading product data from different sources
  We need to have one source of truth and data structure that all products will follow
*/
export function transformShopifyProductForAnalytics({ product, variantId }: {product: any, variantId: string}) {
  return {
    product: {
      id: product.id,
      title: product.title,
      category: product.productType,
      price: product.variants[0].price,
      path: '', // TODO we use to hardcode this, can we create it programmatically?
      img_url: product.image,
    },
    id: variantId,
  };
}
