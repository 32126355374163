//
//
//
//

import { getUserTraits } from '@fc/app-common';
import { Actions } from '@/shared/store/actionsTypes';
import { getCookie, setCookie } from '~/utils/cookies';
import { brochureModal } from '~/store/actions/modals/brochurePayload';
import storeMutations from '~/store/constants/storeMutations';
import { mapGetters } from 'vuex';
import navTypes from '~/store/constants/navTypes';
import EventBus from '../src/shared/scripts/eventbus';

export const FIFTEEN_SECONDS = 15000;
export const VISITED = 1;
export const EXPIRES_IN_SEVEN_DAYS = 7;

export default {
  name: 'EmailPopup',
  methods: {
    displayModal() {
      if (this.canDisplayModal) {
        this.$store.commit(storeMutations.SET_MODAL_SUPPRESSION, true);
        this.$store.dispatch(Actions.OPEN_MODAL, brochureModal);
      }
    },
  },
  computed: {
    ...mapGetters([
      'suppressModal',
      'navType',
    ]),
    newSession() {
      const hasPopUpCookie = getCookie('fcoptoutpopup');
      const hasUserTraits = Object.keys(getUserTraits()).length > 0;

      return !hasUserTraits && !hasPopUpCookie;
    },
    canDisplayModal() {
      return this.newSession && !this.suppressModal && this.navType === navTypes.primary || this.navType === navTypes.marketplace;
    },
  },
  mounted() {
    setTimeout(this.displayModal, FIFTEEN_SECONDS);
    EventBus.$on('brochureModalClosed', () => {
      try {
        const name = 'BrochurePopUp';
        this.$analytics.campaignDismissed({
          name,
          type: 'modal',
        });

        this.$ecommAnalytics.brochurePopUp('Close');
        setCookie('fcoptoutpopup', VISITED, EXPIRES_IN_SEVEN_DAYS);
      } catch (error) {
        console.error(error);
      }
    });
  },
};
