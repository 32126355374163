// eslint-disable-next-line import/no-extraneous-dependencies
import { Plugin } from '@nuxt/types';
import GoogleMapsService from '~/services/googleMaps/googleMapsService';

const googleMapsPlugin: Plugin = (_context, inject) => {
  const googleMapsService = new GoogleMapsService();
  inject('googleMapsService', googleMapsService);
};

export default googleMapsPlugin;
