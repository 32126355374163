

















import { defineComponent, PropType } from '@vue/composition-api';

export type TBgColor = 'fc-bg-eggshell' | 'fc-bg-blue' | 'fc-bg-white';

export default defineComponent({
  name: 'NavigationBar',
  props: {
    bgColor: {
      type: String as PropType<TBgColor>,
      default: 'fc-bg-white',
    },
  },
});
