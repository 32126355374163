<template>
  <div class="fc-input" :class="{ 'fc-input__error': error }">
    <input
      :id="name"
      ref="inputObj"
      :value="value"
      maxlength="100"
      :min="min"
      class="fc-input__input"
      :class="{ 'fc-input__label--error': error, 'fc-input__input--uppercase': upperCaseOnly, 'fc-input__input--lowercase': lowercaseOnly }"
      placeholder=" "
      :type="type"
      :name="name"
      :autocomplete="autocomplete"
      :disabled="loading"
      :required="required"
      :autofocus="autofocus"
      @input="$emit('input', $event.target.value)"
      @keyup.enter="$emit('handleSubmit')"
      @blur="$emit('blur')"
      @keypress="$emit('keypress', $event)"
      @focus="$emit('focus')">
    <label
      :for="name"
      class="fc-input__label"
      :class="{ 'fc-input__label--error': error }">
      {{ title }}
    </label>
  </div>
</template>

<script lang="ts">
  import {
    ref, defineComponent, onMounted, Ref,
  } from '@vue/composition-api';
  import { FCInputProps } from './types';

  interface FCInputBindings {
    inputObj: Ref<null | HTMLInputElement>;
  }

  export default defineComponent<FCInputProps, FCInputBindings>({
    name: 'FCInput',
    props: {
      title: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: true,
        default: 'text',
      },
      error: {
        type: Boolean,
        required: false,
        default: false,
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
      name: {
        type: String,
        required: true,
      },
      autocomplete: {
        type: String,
        required: false,
        default: '',
      },
      focus: {
        type: Boolean,
        required: false,
        default: false,
      },
      autofocus: {
        type: Boolean,
        required: false,
        default: false,
      },
      required: {
        type: Boolean,
        required: false,
        default: false,
      },
      value: {
        type: String,
        default: '',
      },
      upperCaseOnly: {
        type: Boolean,
        required: false,
        default: false,
      },
      lowercaseOnly: {
        type: Boolean,
        required: false,
        default: false,
      },
      min: {
        type: String,
        required: false,
        default: '',
      },
    },
    setup({ focus }) {
      const inputObj : Ref<null | HTMLInputElement> = ref(null);

      onMounted(() => {
        if (focus) {
          inputObj.value!.focus();
        }
      });

      return {
        inputObj,
      };
    },
  });
</script>

<style scoped lang="scss">
  @import "../../styles/exports";

  .fc-input {
    width: 100%;
    position: relative;

    &__error input,
    &__error input:focus {
      border-color: $color-red;
    }

    &__input {
      position: relative;
      margin-bottom: get-spacing-level(6);
      width: 100%;
      border-radius: 29px;
      background-color: $color-white;
      box-sizing: border-box;
      border: $border-light-outline;
      font-size: get-font-size-level(6);
      padding: get-spacing-level(3) get-spacing-level(4);
      color: $color-black;
    }

    &__input--uppercase {
      text-transform: uppercase;
    }

    &__input--lowercase {
      text-transform: lowercase;
    }

    &__label {
      position: absolute;
      pointer-events: none;
      left: 20px;
      top: 16px;
      transition: 0.2s ease all;
      color: $color-gray;
      font-size: get-font-size-level(5);
    }

    &__label--error {
      color: $color-red;
    }

    input:disabled ~ &__label {
      top: 4px;
      bottom: 10px;
      font-size: 10px;
      opacity: 1;
    }

    input[type="date"] {
      height: 52px;
    }

    input:focus ~ &__label,
    input:not(:placeholder-shown) ~ &__label {
      top: 4px;
      bottom: 10px;
      font-size: get-font-size-level(3);
      opacity: 1;
    }

    input:not(:empty) ~ .fc-input__label {
      top: 4px;
      bottom: 10px;
      font-size: 10px;
      opacity: 1;
    }

    &__input:disabled {
      background-color: $color-light-gray;
    }

    &__input:focus {
      border: 1px solid $color-black;
      outline: none;
    }
  }
</style>
