














import Storyblok, { StoryblokComponent } from 'storyblok-js-client';
import { defineComponent } from '@vue/composition-api';
import { CMSService } from '~/services/storyBlok/cmsService';
import DriftFacade from '~/components/DriftFacade.vue';
import storeMutations from '~/store/constants/storeMutations';

interface LayoutStoryResp {
  body: StoryblokComponent<string>[];
}

export default defineComponent({
  name: 'BaseLayout',
  components: { DriftFacade },
  data() {
    return {
      layoutStory: [] as StoryblokComponent<string>[],
    };
  },
  async fetch() {
    const storyBlok = new CMSService({ cmsService: this.$storyapi as Storyblok });
    const storyblokData = await storyBlok.get('data/navigation');
    const storyResp = storyblokData?.data?.story?.content || [];

    this.$store.commit(storeMutations.SET_NAV_DATA, storyResp);

    const layoutData = await storyBlok.get('layout');
    const layoutContent: LayoutStoryResp = layoutData?.data?.story?.content || [];
    this.layoutStory = layoutContent.body;
  },
  computed: {
    isNotConfirmation() {
      return this.$store.getters.navType !== 'confirmation';
    },
    isNotGQ() {
      return this.$store.getters.navType !== 'gq';
    },
  },
});
