import { Loader } from '@googlemaps/js-api-loader';

export default class GoogleMapsService {
  constructor() {
    const googleMaps = new Loader({
      apiKey: process.env.NUXT_ENV_GOOGLE_MAPS_KEY,
      version: 'weekly',
      libraries: ['places'],
    });

    googleMaps.load().then((google) => {
      this.places = new google.maps.places.AutocompleteService();
      this.sessionToken = new google.maps.places.AutocompleteSessionToken();
      this.geocoder = new google.maps.Geocoder();
    });
  }

  async getPlaces(searchValue) {
    const sessionToken = this.sessionToken;
    const response = await this.places.getPlacePredictions({
      input: searchValue,
      sessionToken,
      componentRestrictions: { country: ['us', 'ca', 'au', 'gb'] },
      fields: ['formatted_address'],
      types: ['address'],
    });

    return response.predictions || {};
  }

  async getGeoCodedAddress(placeId) {
    let resultResponse = [];

    await this.geocoder.geocode({ placeId }, (results, status) => {
      // eslint-disable-next-line no-undef
      if (status === google.maps.GeocoderStatus.OK) {
        resultResponse = results;
      }
    });

    return resultResponse;
  }

  async getCountry(lat, lng) {
    const res = await this.geocoder.geocode({ location: { lat, lng } });
    const countryResult = res?.results[0]?.address_components.find((component) => component.types.includes('country'));
    return countryResult?.short_name ?? null;
  }
}
