import Vue from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Plugin } from '@nuxt/types';
import BugsnagWrapper from '../services/BugSnagService';

const Bugsnag = BugsnagWrapper.getInstance();

const bugsnagVue = Bugsnag.getPlugin('vue');
bugsnagVue?.installVueErrorHandler(Vue);
const bugsnagPlugin: Plugin = (_, inject) => {
  inject('bugsnag', Bugsnag);
};

export default bugsnagPlugin;
