import Bugsnag, { Client } from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

interface IBugsnagWrapper {
  notify(error: Error, severity?: 'info' | 'warning' | 'error'): void;
  setUser(id: string, email: string, name: string): void;
}

const isProd = process.env.NUXT_ENV_IS_PROD === 'true';
const isStaging = process.env.NUXT_ENV_IS_STAGING === 'true';
// eslint-disable-next-line no-nested-ternary
const releaseStage = isStaging ? 'staging' : isProd ? 'production' : 'development';

class BugsnagWrapper implements IBugsnagWrapper {
  private static instance: BugsnagWrapper | null = null;

  private bugsnagClient: Client;

  private constructor() {
    console.log('Package Version', process.env.appVersion);
    console.log('Release Stage', releaseStage);

    this.bugsnagClient = Bugsnag.start({
      apiKey: process.env.NUXT_ENV_BUGSNAG_SHOP_API_KEY ?? '',
      plugins: [new BugsnagPluginVue()],
      releaseStage,
      appVersion: process.env.appVersion ?? '0.0.0',
    });
  }

  public static getInstance(): BugsnagWrapper {
    if (!BugsnagWrapper.instance) {
      BugsnagWrapper.instance = new BugsnagWrapper();
    }
    return BugsnagWrapper.instance;
  }

  public notify(error: any, severity: 'info' | 'warning' | 'error' = 'error'): void {
    const errorToCapture = error instanceof Error ? error : new Error(error);

    this.bugsnagClient.notify(errorToCapture, event => {
      // eslint-disable-next-line no-param-reassign
      event.severity = severity;
    });
  }

  public setUser(id: string, email: string, name: string): void {
    this.bugsnagClient.setUser(id, email, name);
  }

  public getPlugin(pluginName: string): any {
    return this.bugsnagClient.getPlugin(pluginName);
  }

  public leaveBreadcrumb(message: string, metadata: any): void {
    this.bugsnagClient.leaveBreadcrumb(message, metadata);
  }
}

export default BugsnagWrapper;
