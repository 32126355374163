<template>
  <div
    :class="getContainerClass"
    @click="handleClick"
    @mouseover="handleMouseOver"
    @mouseout="handleMouseOut">
    <video
      v-bind="videoBindings"
      ref="videoRef"
      :autoplay="isAutoPlayback"
      class="video"
      width="100%"
      height="100%"
      playsinline
      controlslist="nodownload"
      loop
      @pause="handleVideoPlaying"
      @playing="handleVideoPlaying">
      <slot />
    </video>

    <div v-if="showPlayIcon" class="video__play">
      <font-awesome-icon
        class="video__play-icon"
        :icon="['fc', 'play']"
        height="80"
        width="80" />
    </div>

    <div
      v-if="isOverlay"
      aria-hidden="true"
      class="video__overlay fc-flex fc-column" />
  </div>
</template>

<script lang="ts">
  import {
    defineComponent,
    computed,
    ref,
    PropType,
  } from '@vue/composition-api';
  import { VideoPlayback } from './types';

  export default defineComponent({
    name: 'Video',
    props: {
      videoKey: {
        type: String,
        default: null,
      },
      firstFrameImageUrl: {
        type: String,
        required: true,
        default: null,
      },
      isOverlay: {
        type: Boolean,
        default: false,
      },
      videoMuted: {
        type: Boolean,
        default: true,
      },
      isBackground: {
        type: Boolean,
        default: false,
      },
      videoPlayback: {
        type: String as PropType<VideoPlayback>,
        default: VideoPlayback.auto,
      },
      controls: {
        type: Boolean,
        default: false,
      },
      hasPlayIcon: {
        type: Boolean,
        default: false,
      },
      rounded: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const videoRef = ref<HTMLVideoElement>();
      const videoPaused = ref(true);

      const playVideo = async () => {
        if (!videoRef.value?.paused) return;

        await videoRef.value?.play();
      };

      const pauseVideo = () => {
        videoRef.value?.pause();
      };

      const videoBindings = computed(() => ({
        poster: props.firstFrameImageUrl,
        ...props.videoKey ? { key: props.videoKey } : {},
        ...props.controls ? { controls: true } : {},
        ...props.videoMuted ? { muted: true } : {},
      }));

      return {
        handleVideoPlaying() {
          videoPaused.value = !!videoRef.value?.paused;
        },
        async handleClick() {
          if (props.videoPlayback === VideoPlayback.onClick) {
            await playVideo();
          }
        },
        async handleMouseOver() {
          if (props.videoPlayback === VideoPlayback.onHover) {
            await playVideo();
          }
        },
        handleMouseOut() {
          if (props.videoPlayback === VideoPlayback.onHover) {
            pauseVideo();
          }
        },
        videoRef,
        videoBindings,
        showPlayIcon: computed(() => (!videoPaused.value ? false : props.hasPlayIcon)),
        getContainerClass: computed(() => {
          const wrapperClass= props.isBackground ? 'video__wrapper--background' : 'video__wrapper';
          return {
            [wrapperClass]: true,
            'video-rounded': props.rounded,
          };
        }),
        isAutoPlayback: computed(() => (props.videoPlayback === VideoPlayback.auto ? 'true' : null)),
      };
    },
  });
</script>

<style scoped lang="scss">
  @import "../../styles/exports";

  .video__wrapper {
    display: flex;
    position: relative;
    overflow: hidden;

    &--background {
      position: relative;
      height: 100%;
      width: 100%;
      overflow: hidden;
      display: flex;

    }
  }

  .video-rounded {
    border-radius: 18px;
  }

  .video {
    object-fit: cover;
    width: 100%;
    height: 100%;

    &__overlay {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      align-items: stretch;
      justify-content: stretch;
      background-image: radial-gradient(ellipse at center, transparent 20%, $color-black 80%);
    }

    &__play {
      position: absolute;
      height: 5rem;
      width: 5rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;

      &-icon {
        color: $color-white;
        height: 100%;
        width: 100%;
      }
    }
  }
</style>
